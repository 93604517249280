import React from "react"
import getUserType from "@utils/getUserType"
import { useAuth } from "@auth/useAuth"
import { Link } from "gatsby"
import { toast } from "react-toastify"

const UsersTable = ({ list = [], refresh = () => null }) => {
  const { makeRequest } = useAuth() || {}

  const resetUserPassword = (e, name, email, id, auth0_id) => {
    e.preventDefault()
    if (typeof window === "undefined") return
    const consent = window.confirm(`Reset password for ${name} (${email}) ?`)

    if (!consent) return

    makeRequest("users/reset-password", {
      id,
      email,
      auth0_id,
    }).then((res) => {
      toast.success("Password reset email sent.")
      refresh()
    })
  }
  const deleteUser = (e, name, email, id, auth0_id) => {
    e.preventDefault()
    if (typeof window === "undefined") return
    const consent = window.confirm(`Delete user ${name} (${email}) ?`)

    if (!consent) return

    makeRequest("users/delete", {
      id,
      auth0_id,
    }).then((res) => {
      toast.success("Deleted.")
      refresh()
    })
  }
  if (list.length === 0) {
    return (
      <table className={"table table-bordered"}>
        <tbody>
          <tr>
            <td className={"text-center"}>No users available</td>
          </tr>
        </tbody>
      </table>
    )
  }
  return (
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th style={{ width: "25%" }}>Name</th>
          <th style={{ width: "25%" }} className={"text-center"}>
            Email
          </th>
          <th style={{ width: "25%" }} className={"text-center"}>
            Role
          </th>
          <th style={{ width: "25%" }} className={"text-center"}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {list.length > 0 ? (
          list.map(({ id, name, email, contact_name, user_type, auth0_id }) => {
            return (
              <tr key={id}>
                <td>
                  {name || contact_name}{" "}
                  {auth0_id && (
                    <>
                      <br />
                      <span style={{ whiteSpace: "nowrap" }}>{auth0_id}</span>
                    </>
                  )}
                </td>
                <td className={"text-center"}>{email}</td>
                <td className={"text-center"}>{getUserType(user_type)}</td>
                <td className={"text-center"}>
                  <a
                    href={`#`}
                    onClick={(e) =>
                      resetUserPassword(
                        e,
                        name || contact_name,
                        email,
                        id,
                        auth0_id
                      )
                    }
                  >
                    Reset Password
                  </a>
                  {user_type !== "ws-admin" && (
                    <>
                      {user_type !== "api-user" && (
                        <>
                          {" "}
                          |{" "}
                          <Link
                            to={`/users/edit?user_id=${id}`}
                            className={"text-warning"}
                          >
                            Edit
                          </Link>
                        </>
                      )}{" "}
                      |{" "}
                      <a
                        href={`#`}
                        onClick={(e) =>
                          deleteUser(
                            e,
                            name || contact_name,
                            email,
                            id,
                            auth0_id
                          )
                        }
                        className={"text-danger"}
                      >
                        Delete
                      </a>
                    </>
                  )}
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={"3"}>No users yet.</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default UsersTable
