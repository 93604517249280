const getUserType = type => {
  switch (type) {
    case "so-admin":
      return "Administrator"
    case "so-user":
      return "Manager"
    case "ws-admin":
      return "Super Admin"
    case "api-user":
      return "API User"
    default:
      return "Invalid"
  }
}

export default getUserType
