import React, { useContext, useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import * as styles from "./index.module.less"
import { Link } from "gatsby"
import { Button, ButtonGroup } from "reactstrap"
import { SpaceContext } from "@hooks/SpaceContext"
import get from "lodash/get"
import UsersTable from "./_UsersTable"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"

const SpaceOperators = () => {
  const {
    data: { list, unallocatedSpaces, unallocatedUsers },
    actions: { setSpaces, setValues },
  } = useContext(SpaceContext)

  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [progress, setProgress] = useState(true)
  const [show, setShow] = useState(0)
  const [users, setUsers] = useState(0)
  const [so, setSo] = useState(0)

  const [showActiveSos, setShowActiveSos] = useState(true)

  const toggleUser = (idx) => {
    setShow(0)
    setSo(0)
    setUsers(users === idx ? 0 : idx)
  }
  const toggleShow = (idx) => {
    setUsers(0)
    setSo(0)
    setShow(show === idx ? 0 : idx)
  }

  const toggleSoData = (idx) => {
    setUsers(0)
    setShow(0)
    setSo(so === idx ? 0 : idx)
  }

  useEffect(() => {
    if (isAuthenticated) refresh()
  }, [isAuthenticated])

  const refresh = () => {
    setProgress(true)
    makeRequest("fetch-categorized-locations").then((res) => {
      setProgress(false)
      setValues({
        list: res.spaces,
        locationsCategorizedBySpaceOperator: res.spaces,
        unallocatedSpaces: res.unallocatedSpaces,
        unallocatedUsers: res.unallocatedUsers,
      })
    })
  }

  return (
    <>
      <SEO title="Location Providers" />
      <Layout title={"Location Providers"} progress={progress}>
        <ButtonGroup>
          <Button
            color={showActiveSos ? "primary" : "light"}
            onClick={() => setShowActiveSos(true)}
          >
            Active
          </Button>
          <Button
            color={!showActiveSos ? "primary" : "light"}
            onClick={() => setShowActiveSos(false)}
          >
            Inactive
          </Button>
        </ButtonGroup>
        <br />
        <br />
        {(list || [])
          .filter(({ domain }) => {
            if (showActiveSos) {
              return !!domain
            } else {
              return !domain
            }
          })
          .map((sp) => {
            return (
              <div key={sp.id}>
                <div
                  className={`${styles.sp_block} ${
                    show === sp.id || users === sp.id || so === sp.id
                      ? styles.sp_block_active
                      : ""
                  }`}
                >
                  <p>
                    <strong>{sp.name}</strong> ({sp.spaces.length} space
                    {sp.spaces.length === 1 ? "" : "s"} / {sp.users.length} user
                    {sp.users.length === 1 ? "" : "s"})
                  </p>
                  <Link
                    to={`/admin/space-operators/edit?id=${sp.id}`}
                    className={"btn btn-sm btn-primary"}
                    size={"sm"}
                    // onClick={() => toggleSoData(sp.id)}
                  >
                    View SO
                  </Link>
                  <Button
                    color={users === sp.id ? "warning" : "primary"}
                    size={"sm"}
                    className={"mx-2"}
                    onClick={() => toggleUser(sp.id)}
                  >
                    View users
                  </Button>
                  <Button
                    color={show === sp.id ? "warning" : "primary"}
                    size={"sm"}
                    onClick={() => toggleShow(sp.id)}
                  >
                    View spaces
                  </Button>
                </div>
                {so === sp.id && <SoData data={sp} />}
                {show === sp.id && <Table list={sp.spaces} />}
                {users === sp.id && (
                  <UsersTable list={sp.users} refresh={refresh} />
                )}
              </div>
            )
          })}

        <br />
        <hr />
        <br />

        {unallocatedUsers?.length > 0 || unallocatedSpaces?.length > 0 ? (
          <UnallocatedSpaces
            unallocatedSpaces={unallocatedSpaces}
            unallocatedUsers={unallocatedUsers}
            toggleShow={toggleShow}
            toggleUser={toggleUser}
            show={show}
            users={users}
            refresh={refresh}
          />
        ) : (
          ""
        )}
      </Layout>
    </>
  )
}

const SoData = ({ data = {} }) => {
  return (
    <table className="table table-hover table-bordered">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{data.name}</td>
        </tr>
        <tr>
          <td>Slug</td>
          <td>
            <span
              onClick={() => {
                if (typeof navigator !== undefined) {
                  navigator.clipboard.writeText(data.slug)
                }
              }}
            >
              {data.slug}
            </span>
          </td>
        </tr>
        <tr>
          <td>ID</td>
          <td>
            <span
              onClick={(e) => {
                if (typeof navigator !== undefined) {
                  navigator.clipboard.writeText(data.id)
                }
              }}
            >
              {data.id}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const UnallocatedSpaces = ({
  unallocatedSpaces,
  unallocatedUsers,
  users,
  show,
  toggleUser = () => null,
  toggleShow = () => null,
  refresh,
}) => {
  return (
    <>
      <div
        className={`${styles.sp_block} ${
          show === "unallocated" || users === "unallocated"
            ? styles.sp_block_active
            : ""
        }`}
      >
        <p>
          <strong>Not Assigned</strong> ({unallocatedSpaces.length} space
          {unallocatedSpaces.length === 1 ? "" : "s"} /{" "}
          {unallocatedUsers.length} user
          {unallocatedUsers.length === 1 ? "" : "s"})
        </p>
        <Button
          color={users === "unallocated" ? "warning" : "primary"}
          size={"sm"}
          className={"mx-2"}
          onClick={() => toggleUser("unallocated")}
        >
          View users
        </Button>
        <Button
          color={show === "unallocated" ? "warning" : "primary"}
          size={"sm"}
          onClick={() => toggleShow("unallocated")}
        >
          View spaces
        </Button>
      </div>
      {show === "unallocated" && <Table list={unallocatedSpaces} />}
      {users === "unallocated" && (
        <UsersTable list={unallocatedUsers} refresh={refresh} />
      )}
    </>
  )
}

const Table = ({ list = [] }) => {
  if (list.length === 0) {
    return (
      <table className={"table table-bordered"}>
        <tbody>
          <tr>
            <td className={"text-center"}>No spaces available</td>
          </tr>
        </tbody>
      </table>
    )
  }
  return (
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Location name</th>
          <th style={{ width: "30%" }} className={"text-center"}>
            Currency | Timezone | Tax
          </th>
          <th style={{ width: "16%" }} className={"text-center"}>
            City
          </th>
          <th style={{ width: "8%" }} className={"text-center"}>
            Status
          </th>
          <th style={{ width: "8%" }} className={"text-center"} />
          <th style={{ width: "8%" }} className={"text-center"} />
        </tr>
      </thead>
      <tbody>
        {list.map((space) => {
          return (
            <tr key={space.id}>
              <td>{space.space_name}</td>
              <td className={"text-center"}>
                {space.currency} | {space.timezone} | {space.tax_percentage}
              </td>
              <td className={"text-center"}>
                {space.city || get(space, "office_address.city", " - ")}
              </td>
              <td
                className={`text-center ${
                  space.is_live ? "text-success" : "text-warning"
                }`}
              >
                {space.is_live ? "Active" : "Inactive"}
              </td>
              <td>
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault()
                    try {
                      navigator.clipboard.writeText(
                        `https://book.worksimply.com/location/${space.id}?expressCheckout=%E2%9C%93&source=qr`
                      )
                      toast.success("Link copied")
                    } catch (e) {
                      console.log(e)
                    }
                  }}
                >
                  QR Link
                </a>
              </td>
              <td className={"text-center"}>
                <Link to={`/location/${space.id}`}>Edit</Link>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
export default SpaceOperators
